<template>
  <v-container>
    <!-- 演習時ヘッダー -->
    <v-app-bar app :color="headerColor" dark dense height="40px">
      <span v-if="saveData.option.selMode">
        {{ saveData.option.selMode.text }}
      </span>
      <span v-if="vifPractice"> ｜ 累計 {{ amountPractice }} 問目 </span>
      <v-spacer></v-spacer>
      <font size="3"
        >第{{ perm.qNum + 1 }}問 / {{ perm.questionData.length }} ｜ 問題ID：{{
          cont.questionId
        }}
      </font>
      <v-spacer></v-spacer>
      <a @click="exit(true)">
        <div style="text-align: center">
          <v-icon large>mdi-exit-run</v-icon>
        </div>
      </a>
    </v-app-bar>
    <center>
      <div style="max-width: 880px">
        <!-- 問題コマンド表示 -->
        <v-card color="black" outlined width="100%" min-height="150px">
          <v-card-text
            style="
              color: white;
              white-space: pre-wrap;
              word-wrap: break-word;
              text-align: left;
              font-size: 20px;
              line-height: 130%;
            "
          >
            <span v-if="isCisco" v-html="cont.questionC"></span>
            <span v-if="!isCisco">localhost:˜#</span>
            &nbsp;
            <div style="display: inline-flex">
              <form @submit.prevent="judgeInput">
                <input
                  type="text"
                  ref="focusInput"
                  v-model="cont.input"
                  style="color: white; width: 600px"
                />
                <button type="submit"></button>
                <!--type="submit"がないとinputでenterしても発火しない-->
              </form>
            </div>
            <!-- 解答表示 -->
            <div v-if="cont.vifWarning" style="color: yellow">
              <br />
              ⚠Warning {{ cont.inputCommand }}
              <br />
              {{ cont.message }}
              <br />
            </div>
            <div v-if="cont.vifAns" style="color: yellow">
              <br />
              正解：{{ cont.answer }}
            </div>
            <br />
            <br />
            <!-- NEXTボタン -->
            <form
              @submit.prevent="nextQuestion"
              style="text-align: right"
              v-if="vifPractice"
            >
              <v-btn
                id="nextBtn"
                @click="nextQuestion()"
                @focus="btnColor(true)"
                @blur="btnColor(false)"
                height="30px"
                :color="cont.colorNextBtn"
              >
                next
              </v-btn>
              <button type="submit"></button>
            </form>
          </v-card-text>
        </v-card>
        <!-- 問題文 -->
        <v-card outlined width="100%">
          <v-card-text
            style="
              white-space: pre-wrap;
              word-wrap: break-word;
              text-align: left;
              font-size: 20px;
              line-height: 130%;
            "
          >
            <span v-html="cont.questionS"></span>
          </v-card-text>
        </v-card>
        <!-- 解説文 -->
        <div v-if="cont.vifCom">
          <br />
          <v-card outlined color="grey lighten-2" width="100%">
            <v-card-title>解説</v-card-title>
            <v-card-text
              style="
                white-space: pre-wrap;
                word-wrap: break-word;
                text-align: left;
                font-size: 20px;
                line-height: 130%;
              "
            >
              <span v-html="cont.comment"></span>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </center>
    <!-- 操作備考 -->
    <center>
      <div
        width="100%"
        max-width="880px"
        style="text-align: left; line-height: 200%"
      >
        <br />
        <li>コマンドを入力してEnterを押すと、答え合わせをします。</li>
        <li>
          練習モードでは、<v-btn color="yellow" height="30px">next</v-btn>
          ボタンが黄色の時にEnterキーを押すと、次の問題に進みます。
        </li>
        <li>練習モードでは、問題はループします。</li>
        <span v-if="!isCisco">
          <li>
            オプションの指定に関して、複数パターンの表記に対応していない問題があります。ご了承ください。
          </li>
        </span>
        <span v-if="isCisco">
          <li>
            管理番号を入力するときは半角スペースを入れてください。【✕】FastEthernet0/1
            【〇】FastEthernet 0/1
          </li>
          <li>いづれの問題でも「do」コマンドは使用しないものとします。</li>
        </span>
      </div>
    </center>
    <!-- スナックバー -->
    <v-snackbar
      v-model="snackbar"
      :color="colorSnackBar"
      :timeout="1000"
      top
      right
      style="margin-top: 40px"
    >
      <span v-if="judgeSnackBar"
        ><v-icon color="red" large>mdi-circle-outline</v-icon></span
      >
      <span v-else><v-icon color="blue" large>mdi-close-thick</v-icon></span>
    </v-snackbar>
    <!-- 結果ダイアログ -->
    <v-dialog v-model="resultDialog" persistent max-width="350px">
      <v-card>
        <v-card-title>
          <span style="white-space: pre-wrap; word-wrap: break-word">
            {{ resultMsg }}
          </span>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="toKamokuHome()"
            >問題選択に戻る</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style>
.slide-base {
  max-width: 900px;
}
.slide {
  height: 0px;
  position: relative;
  width: 100%;
  overflow: hidden;
  /*padding-bottom: 50%;*/
  padding-top: 56.25%;
}
.slide iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}
</style>

<script>
import { mapState } from 'vuex';

export default {
  name: 'command-questions',
  components: {},
  data() {
    //======================================================================================
    return {
      isCisco: false, //Ciscoかそれ以外かの判定
      vifPractice: true, //practice用の表示、falseだとテスト用
      headerColor: 'orange darken-1',
      snackbar: false, //テスト時の、スナックバーの表示制御
      colorSnackBar: '', //テスト時の判定スナックバーの色
      judgeSnackBar: '', //テスト時の判定スナックバーの内容

      amountPractice: 1, //練習モードにて、総取組数の計算に使用しています
      resultDialog: false, //結果表示ダイアログのON・OFF
      resultMsg: '', //結果メッセージ
    };
  },
  computed: {
    ...mapState(['webApp', 'saveData', 'cont', 'contBtn', 'perm']),
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      if (vm.saveData.selKamokuName == '') {
        vm.$store.commit('leavePage', true);
        vm.$router.push('/home');
        return false;
      }
      vm.$store.commit('leavePage', false);
      vm.startTest();
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.webApp.allowLeavePage) {
      next();
    } else {
      //exitする場合は、allowLeavePage = true にしてからrouter操作をするので、2週目でnext()できる
      this.exit(); //ブラウザバックをしている状況なので、引数なし
    }
  },
  created() {
    window.addEventListener('beforeunload', this.handler);
  },
  destroyed() {
    window.removeEventListener('beforeunload', this.handler);
  },
  mounted() {
    this.$store.commit('mainHeader', false);
    this.$refs.focusInput.focus(); //入力欄にフォーカスさせる
  },
  methods: {
    //問題作成
    async startTest() {
      const selKamokuName = this.saveData.selKamokuName;
      if (selKamokuName == 'ciscoCommand') {
        this.isCisco = true;
      }
      await this.contQ_createMain(); //controlQuiz.jsを使っていますよ！
      await this.setQuestionMain();

      const selMode = this.saveData.option.selMode['value'];
      //テストモードの設定
      if (selMode == 'test') {
        this.vifPractice = false; //練習時には総取組数などを表示するので、そのON・OFFに使用しています。
        this.headerColor = '';
      }
      //問題開始時刻の監視
      this.$store.commit('update_store', {
        path: 'saveData',
        key: 'startTime',
        data: new Date().getTime(),
      });
    },
    //問題制御
    setQuestionMain() {
      //検索表示の関係で、関数を切り分けている
      const Q = this.perm.questionData; //レコードの取得
      const ind = this.perm.qNum;
      const ele = Q[ind];
      this.contQ_initCont();
      this.setData(ele);
    },
    //作成、1問の開始時間を保存
    setData(ele) {
      //オリジナルデータの保存
      this.$store.commit('update_store', {
        path: 'cont',
        key: 'qOriginal',
        data: ele,
      });
      this.$store.commit('update_store', {
        path: 'cont',
        key: 'questionId',
        data: ele.id,
      });
      this.$store.commit('update_store', {
        path: 'cont',
        key: 'questionC',
        data: ele.questionC,
      });

      if (!ele.tag) {
        this.$store.commit('update_store', {
          path: 'cont',
          key: 'questionS',
          data: ele.questionS,
        });
      } else {
        //contQの関数を使用する。iframeタグにクラスを挿入
        const iframeTag = this.contQ_insertClassTagForIframe(ele.tag);
        const content = ele.questionS + '<br>' + iframeTag;
        this.$store.commit('update_store', {
          path: 'cont',
          key: 'questionS',
          data: content,
        });
      }

      this.$refs.focusInput.focus(); //※グローバル化するときに注意！
    },
    //採点
    //======================================================================================
    judgeInput() {
      const Q = this.perm.questionData; //レコードの取得
      const ind = this.perm.qNum;
      const ele = Q[ind];

      if (this.isCisco) {
        var judge = this.judge_main(ele); //{judge:false, command:, msg:"}
      } else {
        judge = this.judge_common(ele);
      }

      this.cont.tempCandi = []; //judgeが確定した後に初期化する。練習モードで繰り返し練習するとデータが蓄積されてしまう。
      this.cont.isJudged = true;
      const selMode = this.saveData.option.selMode.value;
      if (selMode == 'practice') {
        this.saveResultData(judge.judge); //qNumで代入しているので、ループしても記録が無尽蔵に増えることは無い
        this.initShowAns();
        if (this.isCisco) {
          this.showAns(judge, ele);
        } else {
          this.showAns_common(judge, ele);
        }
      } else if (selMode == 'test') {
        if (judge['judge'] == true) {
          this.colorSnackBar = 'red lighten-4';
          this.judgeSnackBar = true;
        } else {
          this.colorSnackBar = 'blue lighten-4';
          this.judgeSnackBar = false;
        }
        this.snackbar = true;
        this.saveResultData(judge.judge); //true or false のみを渡す
        this.nextQuestion(); //次の問題への処理
      }
    },
    //Ciscoコマンドの判定
    //======================================================================================
    judge_main(ele) {
      const arrInput = this.cont.input.split(' ');
      while (arrInput[arrInput.length - 1] == '') {
        //空白でsplitすると配列には""が格納されています。
        arrInput.pop(); //末尾の空白が無くなるまで空白を削除
      }
      const arrAns = ele['answer'].split(' ');

      var len = arrAns.length;
      if (arrInput.length > arrAns.length) {
        len = arrInput.length; //本来なら、配列の長さが違う時点で不正解だが、間違いポイントを発見するために、通過させる。
      }

      for (var i = 0; i < len; i++) {
        var inputCommand = arrInput[i];
        var comNum = i;
        if (arrAns[0] == 'no') {
          //noコマンドの場合は、コマンド検索番号が1つずれる
          comNum = i - 1;
          if (i == 0) {
            if (inputCommand == 'no') {
              //i=0かつnoコマンドの場合、comNum=-1となりエラーになるのですぐ次へ。
              continue;
            } else {
              return {
                judge: false,
                command: inputCommand,
                msg: 'noコマンドになっていません。',
              };
            }
          }
        }
        if (inputCommand == '') {
          //入力が欠けている場合、空白になる
          return {
            judge: false,
            command: inputCommand,
            msg: '無効な空白があります',
          };
        }
        if (inputCommand == undefined) {
          //あるべきコマンドが見つからない場合
          return {
            judge: false,
            command: inputCommand,
            msg: 'コマンドが足りません。',
          };
        }
        if (arrAns[i] == undefined) {
          //余分なコマンドを入力しすぎていて、正解配列の要素がない
          return {
            judge: false,
            command: inputCommand,
            msg: '余分なコマンドがあります。',
          };
        }

        inputCommand = arrInput[i].toLowerCase(); //小文字で統一する。バリデーション前に実行すると、undefindの場合にエラーになる

        //inputCommandはansCommandの文字列に含まれているか
        if (inputCommand == '*') {
          var regexp = new RegExp('^' + '\\' + inputCommand + '.*'); //正規表現の特殊記号はエスケープさせないといけない。現状*しか出てこない。
        } else {
          regexp = new RegExp('^' + inputCommand + '.*'); //正規表現オブジェクト ここでは正規表現も文字列で記載する
        }

        const match = arrAns[i].toLowerCase().match(regexp); //小文字で統一する
        if (match == null) {
          return {
            judge: false,
            command: inputCommand,
            msg: 'このコマンドは不適切です。',
          };
        }

        //入力がコマンドリストに存在するかを確認。
        const res = this.checkCommandList(
          i,
          comNum,
          regexp,
          inputCommand,
          arrAns,
          ele,
        );
        if (res['judge'] == false) {
          return res;
        }
      }
      return { judge: true };
    },
    checkCommandList(i, comNum, regexp, inputCommand, arrAns, ele) {
      //ciscoCommand特別アルゴリズム
      const device = ele['device'];
      const mode = ele['mode'];
      const selKamokuName = this.saveData.selKamokuName;

      const commandListObj =
        this.$store.state[selKamokuName].questions.commandList;

      const commandList = this.utils_create_arrayObjFromObj(commandListObj);
      const arrMode = commandList.filter(
        (data) => data['device'] == device && data['mode'] == mode,
      );

      if (i >= 0 && i <= 9) {
        //※※ここで第〇コマンドまで調べるかを設定できる
        const commandNum = 'command' + String(comNum); //noコマンドか否かで、comNumが変わるので注意。冒頭で定義。
        var arr = [];
        if (this.cont.tempCandi.length == 0) {
          //tempCandiが空であれば、コマンドリストから候補を生成する
          arr = arrMode;
        } else {
          arr = this.cont.tempCandi; //tempCandiがあるということは、その中のデータを、直前に利用しているはずなので、これを利用する。
        }

        var newCommandList = []; //コマンド候補の配列を生成する
        arr.forEach((ele) => {
          if (ele[commandNum] == '') {
            //あえて空白になっているコマンドがあるので、それを取得する
            newCommandList.push({ id: ele['id'], [commandNum]: '' });
          } else {
            const arrCommand = ele[commandNum].split('/'); //コマンドリストが空白でない場合、配列化する
            arrCommand.forEach((command) => {
              const obj = {
                id: ele['id'],
                [commandNum]: command, //comandNumをそのままKeyにしています
              };
              newCommandList.push(obj);
            });
          }
        });

        var candidate = newCommandList.filter(
          //モードでフィルター。小文字で統一する。空白レコードも取得する。
          (data) =>
            data[commandNum].toLowerCase().match(regexp) ||
            data[commandNum] == '',
        );
        const nonDepliCandi = this.deleteDeplicate(candidate, commandNum); //重複と空白を除外する。空白コマンドは候補にはなり得ないから
        if (nonDepliCandi.length >= 2) {
          var flag = true;
          const isMatch = candidate.find(
            (data) => data[commandNum] == inputCommand,
          );
          if (isMatch == undefined) {
            //完全一致のコマンドがない場合は、候補が複数あるということ
            flag = false; //input「a」だと、「aaa」と「access-list」があり、「a」だけではfindしない
          } else {
            //偶然一致する他のコマンドの場合、解答との完全一致を確認
            if (isMatch[commandNum] != arrAns[i]) {
              flag = false;
            } //input「hoge」に対して、コマンド「hoge」があり、正答が「hoge-list」の可能性がある。
          }
          if (flag == false) {
            var msg = 'コマンドの候補が複数あります。省略し過ぎです。';
            nonDepliCandi.forEach((ele) => {
              msg += '\n' + ele[commandNum];
            });
            return { judge: false, command: inputCommand, msg: msg };
          }
        }

        if (nonDepliCandi.length == 0) {
          //コマンド候補が無い場合は、ユーザが入力したコマンドとの完全一致を判定
          const ansCommand = arrAns[i].toLowerCase(); //小文字で統一する
          if (inputCommand != ansCommand) {
            return {
              judge: false,
              command: inputCommand,
              msg: '正解のコマンドと一致していません。',
            };
          }
        }

        //ここまでのチェックをクリアしているなら、そのcandiは有用なので、次の検索に活用できる。
        this.cont.tempCandi = []; //候補の再格納をするために、初期化
        candidate.forEach((ele) => {
          const id = ele['id']; //idを利用して、本体から必要なデータを抽出する
          const ind = arrMode.findIndex((data) => data['id'] == id);
          this.cont.tempCandi.push(arrMode[ind]);
        });

        return { judge: true };
      }
    },
    deleteDeplicate(arr, commandNum) {
      // 重複を取り除く処理
      var result1 = arr.filter(
        (element, index, self) =>
          self.findIndex((e) => e[commandNum] === element[commandNum]) ===
          index,
      );
      var result2 = result1.filter((data) => data[commandNum] != ''); //空白コマンドがあるレコードは、除外する
      return result2;
    },
    initShowAns() {
      //解答欄の初期化
      this.cont.vifWarning = false;
      this.cont.inputCommand = '';
      this.cont.message = '';
      this.vifComment = false;
      this.comment = '';
    },
    showAns(judge, ele) {
      //Ciscoコマンドの結果表示
      if (judge['judge'] == false) {
        if (judge['command'] != undefined) {
          this.cont.inputCommand = 'at "' + judge['command'] + '"';
        }
        if (judge['msg'] != undefined) {
          this.cont.message = judge['msg'];
        }
        this.cont.vifWarning = true;
        this.cont.input = ''; //練習用にinputを空白に戻す。
        this.$refs.focusInput.focus(); //練習用にinputにフォーカスしておく。
      } else if (judge['judge'] == true) {
        document.getElementById('nextBtn').focus(); //vueでタグに安易にidをつけるのは非推奨らしいが、refでボタンにフォーカスできないのでしかたない。
      }
      this.cont.answer = ele['answer'];
      this.cont.vifAns = true;

      if (ele['comment'] != '') {
        //解説の表示
        this.cont.comment = ele['comment'];
        this.cont.vifCom = true;
      }
    },

    //一般コマンドの判定
    //======================================================================================
    judge_common(ele) {
      let input = this.formatStr(this.cont.input);
      const arrAns = ele['answer'].split('@');
      arrAns.shift(); //先頭は空なので削除
      for (var i = 0; i < arrAns.length; i++) {
        //正解配列を成形する
        arrAns[i] = this.formatStr(arrAns[i]);
      }
      const ind = arrAns.indexOf(input);
      if (ind == -1) {
        //空白だと・・・
        return {
          judge: false,
          command: input,
          msg: 'コマンドにミスがあります。',
        };
      } else {
        return { judge: true };
      }
    },
    formatStr(str) {
      //文字列を成形して返却する。文字列完全一致判定だと成形が重要。
      str = str.replace(/\r?\n/g, ''); //改行コードがあれば削除
      str = str.replace(/\r?\t/g, ''); //tabコードがあれば削除
      str = str.replace(/\r?\r/g, ''); //制御コードがあれば削除

      let arrChara = str.split(''); //入力された文字列を、1文字ずつ配列に格納
      for (var i = arrChara.length - 1; i >= 0; i--) {
        //末尾の空白を削除していく
        if (arrChara[i] == ' ') {
          str = str.slice(0, -1); //文字列の末尾を削除
        } else {
          break; //breakしないと、コマンド中の空白も対象にしてしまう。
        }
      }
      for (var j = 0; j < arrChara.length - 1; j++) {
        //文字列の先頭の空白を削除していく
        if (arrChara[i] == ' ') {
          str = str.slice(1); //文字列の先頭を削除
        } else {
          break; //breakしないと、コマンド中の空白も対象にしてしまう。
        }
      }
      return str;
    },
    showAns_common(judge, ele) {
      //演習問題の使いまわしなので解説が使えません。「選択肢以外の～」と言う具合。
      if (judge['judge'] == false) {
        if (judge['msg'] != undefined) {
          this.cont.message = judge['msg'];
        }
        this.cont.vifWarning = true;
        this.cont.input = ''; //練習用にinputを空白に戻す。
        this.$refs.focusInput.focus(); //練習用にinputにフォーカスしておく。
      } else if (judge['judge'] == true) {
        document.getElementById('nextBtn').focus(); //vueでタグに安易にidをつけるのは非推奨らしいが、refでボタンにフォーカスできないのでしかたない。
      }
      const arrAns = ele['answer'].split('@');
      arrAns.shift(); //先頭は空なので削除
      for (var i = 0; i < arrAns.length; i++) {
        //正解配列を成形する
        arrAns[i] = this.formatStr(arrAns[i]);
      }
      let str = '';
      arrAns.forEach((command) => {
        str += '\n' + command;
      });
      this.cont.answer = str;
      this.cont.vifAns = true;
    },

    btnColor(isFocus) {
      if (isFocus == true) {
        this.cont.colorNextBtn = 'yellow';
      } else {
        this.cont.colorNextBtn = '';
      }
    },
    saveResultData(isTrue) {
      //テスト時の記録、judgeの結果を問題データに加えて、そのままresultDataへぶち込む
      const Q = this.perm.questionData;
      const qNum = this.perm.qNum;
      const ele = Q[qNum];

      const data = {
        id: ele.id,
        qNum: qNum,
        sectionId: ele['sectionId'],
        isCorrect: isTrue,
        cont: this.utils_deepCopy(this.cont), //この問題を制御したContをすべて格納する
        currentState: '', //最後に、kamokuName.qStateに統合させる
        stateLog: '',
      };

      if (this.gf_isLogin()) {
        const newStateObj = this.updateState(isTrue, ele); //問題のnewStateとlogを作成。controlQuizの関数を利用しています。
        data.currentState = newStateObj.currentState; //最後に、kamokuName.qStateに統合させる
        data.stateLog = newStateObj.log;
      }

      this.$store.commit('update_store', {
        path: 'saveData.answerData',
        key: qNum,
        data: data,
      });
    },
    nextQuestion() {
      const selMode = this.saveData.option.selMode.value;
      if (this.cont.isJudged == false && selMode == 'practice') {
        alert(
          'コマンドを入力してください。コマンドを入力してEnterを押します。',
        );
        this.$refs.focusInput.focus();
        return;
      }
      const isFinish = this.isFinish(selMode); //モード別の終了判定
      if (isFinish) {
        alert('問題がすべて終了しました！内容を保存し、結果を表示します。');
        this.saveAndShowResult();
      } else {
        this.amountPractice = this.amountPractice + 1; //練習モードにて、総取組数の計算に使用しています
        this.setQuestionMain();
      }
    },
    isFinish(selMode) {
      //モード別の終了判定
      const Q = this.perm.questionData;
      const qInd = this.perm.qNum;
      if (selMode == 'practice') {
        if (qInd == Q.length - 1) {
          this.perm.qNum = 0;
        } else {
          this.perm.qNum++;
        }
      } else if (selMode == 'test') {
        if (qInd >= Q.length - 1) {
          return true;
        } else {
          this.perm.qNum++;
        }
      }
      return false;
    },

    //保存・結果遷移
    //======================================================================================
    async saveAndShowResult() {
      this.$store.commit('update_store', {
        path: 'saveData',
        key: 'endTime',
        data: new Date().getTime(),
      });

      if (this.gf_isLogin()) {
        await this.contQ_createUserFSAndSave(); //userFSの作成、FSへのmerge、FSからの再取得
      }

      const selMode = this.saveData.option.selMode.value;
      if (selMode == 'practice') {
        this.showPracticeResult();
      } else {
        this.$store.commit('leavePage', true);
        this.$router.push('/result');
      }
    },
    //練習モード、ページ内での結果表示
    //======================================================================================
    showPracticeResult() {
      const startTime = Number(this.saveData.startTime); //取り組み時間
      const endTime = Number(this.saveData.endTime);
      const sec = Math.round((endTime - startTime) / 1000);
      const timeAll = this.utils_formatSecond(sec);

      const Q = this.perm.questionData; //正答率
      let amountQ = Q.length;
      const total = this.amountPractice - 1;
      if (total <= Q.length) {
        //これで実質の回答数がでる
        amountQ = total;
      }

      const answerData = this.utils_create_arrayObjFromObj(
        this.saveData.answerData,
      );
      const correctAmount = answerData.filter(
        (data) => data['isCorrect'] == true,
      ).length;
      const rate = Math.round((correctAmount / amountQ) * 100);

      var resultMsg = '【結果】'; //ユーザに表示するデータの作成
      const amountAll = Number(this.amountPractice) - 1;
      resultMsg += '\n取組問題数：' + amountAll + '問';
      resultMsg += '\n最終正答率：' + rate + '%';
      resultMsg += '\n取組の時間：' + timeAll;

      this.resultMsg = resultMsg;
      this.resultDialog = true;

      this.$store.commit('leavePage', true);
    },
    //離脱処理
    //======================================================================================
    handler(event) {
      //リロード対策
      event.preventDefault();
      event.returnValue = '';
    },
    exit(isExit) {
      //問題からの離脱、ボタンならtrue、ブラウザバックならundifinedとなる
      const kamokuName = this.saveData.selKamokuName;
      const isAssignment = this.$store.state.perm.isAssignment;
      //課題中の離脱
      if (isAssignment) {
        this.exitAssignment(kamokuName);
        return;
      }
      //ｑNumだとループすると0に戻っていることがある。テスト時でもカウントはできている。
      if (this.amountPractice - 1 == 0) {
        var res = window.confirm('問題選択ページに戻ってよろしいですか？');
        if (res) {
          this.$store.commit('leavePage', true);
          this.$router.push('/' + kamokuName);
        } else {
          return;
        }
      } else {
        this.exitMidstream(isExit, kamokuName);
      }
    },
    exitAssignment(kamokuName) {
      var res = window.confirm('⚠ 課題を中止します。よろしいですか？');
      if (res) {
        this.$store.commit('leavePage', true);
        this.$router.push('/' + kamokuName);
      } else {
        return;
      }
    },
    exitMidstream(isExit, kamokuName) {
      const selMode = this.$store.state[kamokuName].option.selMode.value;
      let save = false;
      let exit = false;
      if (selMode == 'practice') {
        //練習モードの場合
        if (isExit == undefined) {
          //ブラウザバックの場合undefinedになる
          let res = window.confirm(
            '⚠ このまま戻ると取組内容は記録されません。\r\n問題選択ページに戻ってよろしいですか？\r\n（※ここまでの学習記録を残す場合は、右上のボタンから戻ってください。）',
          );
          if (res) {
            save = false;
            exit = true;
          } else {
            save = true;
            exit = false;
          }
        } else {
          //exitボタンの場合こっちになる
          let res = window.confirm(
            'ここまでの解答を記録し結果を表示します。よろしいですか？',
          );
          if (res) {
            save = true;
            exit = true;
          } else {
            save = false;
            exit = false;
          }
        }
      } else if (selMode == 'test') {
        //テストモードの場合
        let res = window.confirm(
          '⚠ テストの取組は記録されません。\r\n問題選択ページに戻ってよろしいですか？',
        );
        if (res) {
          save = false;
          exit = true;
        } else {
          save = true;
          exit = false;
        }
      }
      if (exit) {
        //離脱処理
        if (save) {
          //保存して離脱。（練習モードのみ可能）
          this.saveAndShowResult();
        } else {
          //保存せず離脱
          this.$store.commit('leavePage', true);
          this.$router.push('/' + kamokuName);
        }
      } else {
        //キャンセル
        return;
      }
    },
    toKamokuHome() {
      //ページ内で結果表示した後のボタン
      const selKamokuName = this.saveData.selKamokuName;
      this.$store.commit('leavePage', true);
      this.$router.push('/' + selKamokuName);
    },
  },
};
</script>
